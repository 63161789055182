import config from 'src/config';

/**
 * Sets the logged in user
 */
const setLoggedInUser = (user) => {
  localStorage.setItem(config.STORAGE_KEY_USER, JSON.stringify(user));
};

/**
 * Returns the logged in user
 */
const getLoggedInUser = () => {
  const user = localStorage.getItem(config.STORAGE_KEY_USER);
  return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
};

export { setLoggedInUser, getLoggedInUser };
