// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import config from 'src/config';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAHBKEG5QhdldsnrxCSp2us-PcSxh9lNgI',
  authDomain: 'blubberbee-8fd25.firebaseapp.com',
  projectId: 'blubberbee-8fd25',
  storageBucket: 'blubberbee-8fd25.appspot.com',
  messagingSenderId: '726689566334',
  appId: '1:726689566334:web:3181dfd31fef7f519b522f',
  measurementId: 'G-0N7BYSK77V',
};

function InitAnalytic() {
  if (config.ENV === 'production') {
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    getAnalytics(app);
  }
}

export { InitAnalytic };
