import Echo from 'laravel-echo';
import config from 'src/config';
import { messageParser } from '../helpers/chatParser';
import { getAccessToken } from '../helpers/user';

window.io = require('socket.io-client');

export function listenWebsocketEvent(userId, handlers) {
  window.Echo = new Echo({
    broadcaster: 'socket.io',
    host: config.SOCKET_URL,
    port: '2003',
    authHost: config.SOCKET_URL,
    authEndpoint: '/broadcasting/auth',
    transports: ['websocket', 'polling', 'flashsocket'],
    auth: {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        Accept: 'application/json',
      },
    },
  });
  window.Echo.join('USER_' + userId)
    .listen('NewMessageEvent', (response) => {
      var message = messageParser(response.data);
      handlers.NewMessageEvent(message);
    })
    .listen('NewConversationEvent', (response) => {
      console.log(response.data);
    })
    .listen('KickOutEvent', (response) => {
      handlers.KickOutEvent(response.data);
    })
    .listen('UpdateWalletEvent', (response) => {
      handlers.UpdateWalletEvent(response.data);
    })
    .listen('NewNotificationEvent', (response) => {
      handlers.AddNotifications(response.data);
    })
    .listen('UpdateConversationEvent', (response) => {
      handlers.UpdateConversation(response);
    })
    .listen('UpdateMemberEvent', (response) => {
      handlers.UpdateMembers(response);
    })
    .listen('UpdateMessageEvent', (response) => {
      handlers.UpdateMessage(response);
    });
}
