import { APIClient, setAuthorization } from 'src/helpers/apiClient';
import { getAccessToken } from 'src/helpers/user';

const apiClient = new APIClient();
const notificationService = {
  markAsRead: async () => {
    setAuthorization(getAccessToken());
    try {
      await apiClient.get('/api/notifications/mark-as-read');
      return true;
    } catch (err) {
      return false;
    }
  },
};
export { notificationService };
