import {
  FETCH_COURSE_DETAIL,
  FETCH_COURSE_DETAIL_SUCCESS,
  FETCH_COURSE_SUCCESS,
  FETCH_COURSE,
  FETCH_COURSE_FAIL,
  FETCH_COURSE_LECTURE_SUCCESS,
  FETCH_LECTURE_SUCCESS,
  FETCH_COURSE_SECTIONS_SUCCESS,
  CREATE_RATE_COURSE_SUCCESS,
  FETCH_RATE_SUCCESS,
  CREATE_REPLY_RATE_SUCCESS,
  EDIT_RATE_SUCCESS,
} from './constants';
import { getLoggedInUser } from 'src/helpers/authUtils';
const strUser = getLoggedInUser();

const initialState = {
  user: strUser?.user,
  courses: [],
  course: false,
  lecture: {},
  sectionIndex: 0,
  lectureIndexActive: 0,
  currentPage: 0,
  sections: [],
  last_page: 0,
  rating: 0,
  ratings: [],
  teacher: {},
  currentPageRate: 0,
  last_page_rate: 0,
  isProcessing: false,
  isLoadingMore: false,
};
const Course = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COURSE:
      const load_more = action.payload.load_more || false;
      return {
        ...state,
        isProcessing: true,
        isLoadingMore: load_more,
      };
    case FETCH_COURSE_FAIL:
      return {
        ...state,
        isProcessing: false,
        courses: [],
        isLoadingMore: false,
      };
    case FETCH_COURSE_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        isLoadingMore: false,
        courses: action.payload.resetCourse
          ? action.payload.data.data
          : [...state.courses, ...action.payload.data.data],
        currentPage: action.payload.data.current_page,
        last_page: action.payload.data.last_page,
      };
    case FETCH_COURSE_DETAIL: {
      return {
        ...state,
        isProcessing: true,
      };
    }
    case FETCH_COURSE_DETAIL_SUCCESS: {
      return {
        ...state,
        course: action.payload.course,
        rating: action.payload.course.rating,
        teacher: action.payload.teacher,
        isProcessing: false,
        sectionIndex: 0,
      };
    }
    case FETCH_RATE_SUCCESS: {
      return {
        ...state,
        ratings: action.payload.resetRating
          ? action.payload.data.data
          : [...state.ratings, ...action.payload.data.data],
        currentPageRate: action.payload.data.current_page,
        last_page_rate: action.payload.data.last_page,
      };
    }
    case FETCH_COURSE_LECTURE_SUCCESS: {
      const { lectureId, course, sections } = action.payload;
      const sectionIndex = sections.findIndex((sectionItem) =>
        sectionItem.lectures.some((lectureItem) => {
          return lectureId === lectureItem.id.toString();
        })
      );
      const lectureIndex = sections[sectionIndex].lectures.findIndex(
        (lectureItem) => lectureId === lectureItem.id.toString()
      );
      let lecture = {};

      if (lectureIndex !== -1) {
        lecture = sections[sectionIndex].lectures[lectureIndex];
      }
      return {
        ...state,
        lecture: lecture,
        course: course,
        sectionIndex: sectionIndex,
        lectureIndexActive: lectureIndex,
        sections: sections,
      };
    }
    case FETCH_LECTURE_SUCCESS: {
      const { sectionIndex, lectureIndex } = action.payload;
      const updateLecture = state.sections[sectionIndex].lectures[lectureIndex];
      return {
        ...state,
        sectionIndex: sectionIndex,
        lectureIndexActive: lectureIndex,
        lecture: updateLecture,
      };
    }
    case FETCH_COURSE_SECTIONS_SUCCESS: {
      return {
        ...state,
        sections: [...action.payload],
      };
    }
    case CREATE_REPLY_RATE_SUCCESS: {
      const updatedRatings = [...state.ratings];
      const findIndex = updatedRatings.findIndex(
        (rating) => rating.id === action.payload[0].rating_id
      );
      if (findIndex !== -1) {
        updatedRatings[findIndex] = {
          ...updatedRatings[findIndex],
          comments: action.payload,
        };
      }
      return {
        ...state,
        ratings: updatedRatings,
      };
    }

    case CREATE_RATE_COURSE_SUCCESS: {
      const { response, comment } = action.payload;
      const myRating = response.data.ratings.find(
        (rating) => rating.user_id === state.user.id
      );
      const updateCourse = { ...state.course };
      updateCourse.is_reviewed = true;
      const newRating = {
        id: myRating.id,
        rating: myRating.rating,
        comments: [
          {
            id: myRating.id,
            content: comment,
            user: state.user,
            parent_id: null,
          },
        ],
        updated_at: response.data.updated_at,
        user: state.user,
      };
      const pointRating = response.data.rating;
      return {
        ...state,
        ratings: [newRating, ...state.ratings],
        rating: pointRating,
        course: updateCourse,
      };
    }
    case EDIT_RATE_SUCCESS: {
      const { response, params } = action.payload;
      const newRatings = [...state.ratings];
      const updateRatings = newRatings.filter(
        (rating) => rating.id !== params.id
      );
      const ratingEdited = response.data.ratings.find(
        (rating) => rating.id === params.id
      );
      const updateRating = {
        id: params.id,
        rating: params.rating,
        comments: [
          {
            id: params.id,
            content: params.comment,
            user: state.user,
            parent_id: null,
          },
        ],
        updated_at: ratingEdited.updated_at,
        user: state.user,
      };
      return {
        ...state,
        ratings: [updateRating, ...updateRatings],
        rating: response.data.rating,
      };
    }

    default:
      return {
        ...state,
      };
  }
};

export default Course;
