import React from 'react';

import { formatTime } from 'src/helpers/formatTime';
import { NOTI_STATUS } from '../../constants';

import tiemIcon from 'src/assets/images/Icons/Time_white.svg';
import { getThumbnailNotification } from 'src/helpers/notification';
import avatarEmpty from './../../../../assets/images/users/avatar-empty.png';
import { NOTIFICATION_TYPES } from 'src/constants';

const CardNoti = ({ noti, goToPageDetail }) => {
  const { id, created_at, question, status, title, description, type } = noti;
  const thumbnailUrl = getThumbnailNotification(noti);

  const unread = status === NOTI_STATUS.UNREAD;
  let entityId;
  switch (type) {
    case NOTIFICATION_TYPES.NEW_QUESTION:
      entityId = question?.id
      break;
    case NOTIFICATION_TYPES.POST_COMMENT:
      entityId = noti?.comment?.post_id
      break;
    case NOTIFICATION_TYPES.POST_LIKE:
      entityId = noti?.like?.post_id
      break;
    default:
      break;
  }
  return (
    <div
      className={`card_wrapper ${unread ? 'noti_unread' : ''}`}
      key={id}
      onClick={() => goToPageDetail(type, entityId, status, id)}
    >
      <div className="card_left">
        <img
          src={thumbnailUrl ?? avatarEmpty}
          alt="icon"
          className="card_left_icon"
        />
      </div>
      <div className="card_right">
        <div className="card_right_title">{title}</div>
        <div className="card_right_name">{description}</div>
        <div className="card_right_posted">
          <img src={tiemIcon} alt="time" height="12px" width="12px" />
          <span> {formatTime(created_at)}</span>
        </div>
      </div>
      {unread && <div className="notification_unread"></div>}
    </div>
  );
};

export default CardNoti;
