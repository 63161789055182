import {
  FILTER_QUESTIONS_REQUEST,
  GET_QUESTIONS_DETAIL_FAILD,
  GET_QUESTIONS_DETAIL_REQUEST,
  GET_QUESTIONS_DETAIL_SUCCESS,
  GET_QUESTIONS_FAILD,
  GET_QUESTIONS_REQUEST,
  GET_QUESTIONS_SUCCESS,
} from './constants';

const INIT_STATE = {
  isFetchingQuestion: false,
  questions: [],
  question: '',
  last_page: 0,
  processing: false,
  loading_more: false,
  questions_related: []
};

const Questions = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_QUESTIONS_SUCCESS: {
      const { data, last_page, current_page } = action.payload.data;
      if (action.payload.is_related) {
        return {
          ...state,
          questions_related: [...data]
        }
      }
      return {
        ...state,
        questions: current_page === 1 ? data : [...state.questions, ...data],
        last_page: last_page,
        processing: false,
        loading_more: false,
      };
    }
    case GET_QUESTIONS_REQUEST: {
      const { page } = action.payload;
      return {
        ...state,
        processing: page === 1,
        loading_more: page > 1,
      };
    }
    case GET_QUESTIONS_FAILD: {
      return {
        ...state,
        processing: false,
        loading_more: false,
      };
    }

    case GET_QUESTIONS_DETAIL_REQUEST: {
      return {
        ...state,
        isFetchingQuestion: true,
      };
    }

    case GET_QUESTIONS_DETAIL_SUCCESS: {
      const updateQuestions = [...state.questions];
      const indexQuestion = updateQuestions.findIndex(
        (question) => question.id === action.payload.id
      );
      if (indexQuestion !== -1) {
        updateQuestions[indexQuestion] = action.payload;
      }
      return {
        ...state,
        isFetchingQuestion: false,
        question: action.payload,
        questions: updateQuestions,
      };
    }

    case GET_QUESTIONS_DETAIL_FAILD: {
      return {
        ...state,
        isFetchingQuestion: false,
      };
    }

    case FILTER_QUESTIONS_REQUEST: {
      return { ...state };
    }

    default:
      return { ...state };
  }
};

export default Questions;
