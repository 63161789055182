export const ACTIVE_CONVERSATION = 'ACTIVE_CONVERSATION';
export const FULL_CONVERSATION = 'FULL_CONVERSATION';
export const FULL_CONVERSATION_SUCCESS = 'FULL_CONVERSATION_SUCCESS';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const READ_MESSAGE = 'READ_MESSAGE';
export const MAKE_AS_READ = 'MAKE_AS_READ';
export const GET_NEW_CONVERSATION = 'GET_NEW_CONVERSATION';
export const GET_NEW_CONVERSATION_SUCCESS = 'GET_NEW_CONVERSATION_SUCCESS';
export const CREATE_CHAT = 'CREATE_CHAT';
export const CREATE_CHAT_SUCCESS = 'CREATE_CHAT_SUCCESS';
export const SEEN_MESSAGE = 'SEEN_MESSAGE';
export const LOAD_MORE_CONVERSATION = 'LOAD_MORE_CONVERSATION';
export const LOAD_MORE_CONVERSATION_SUCCESS = 'LOAD_MORE_CONVERSATION_SUCCESS';
export const FETCH_MESSAGE = 'FETCH_MESSGAE';
export const FETCH_MESSAGE_SUCCESS = 'FETCH_MESSAGE_SUCCESS';
export const ADD_MESSAGE = 'ADD_MESSAGE';
export const FETCHING_MESSAGE_REQUEST = 'FETCHING_MESSAGE_REQUEST';
export const FETCHING_MESSAGE_SUCCESS = 'FETCHING_MESSAGE_SUCCESS';
export const FETCHING_MESSAGE_FAILED = 'FETCHING_MESSAGE_FAILED';
export const GET_HELP_CENTER = 'GET_HELP_CENTER';
export const GET_HELP_CENTER_SUCCESS = 'GET_HELP_CENTER_SUCCESS';
export const GET_ENGLISH_CLUB = 'GET_ENGLISH_CLUB';
export const GET_ENGLISH_CLUB_SUCCESS = 'GET_ENGLISH_CLUB_SUCCESS';
export const TOGGLE_HELPCENTER = 'TOGGLE_HELPCENETR';
export const TOGGLE_ENGLISHCLUB = 'TOGGLE_ENGLISHCLUB';
export const DELETE_CONVERSATION_SUCCESS = 'RENAME_CONVERSATION_SUCCESS';
export const RENAME_CONVERSATION_SUCCESS = 'DELETE_CONVERSATION_SUCCESS';
export const GET_CHAT_BOT_TYPE = 'GET_CHAT_BOT_TYPE';
export const GET_CHAT_BOT_TYPE_SUCCESS = 'GET_CHAT_BOT_TYPE_SUCCESS';
export const ADD_CONVERSATION_REALTIME = 'ADD_CONVERSATION_REALTIME';
export const UPDATE_CONVERSATION_REALTIME = 'UPDATE_CONVERSATION_REALTIME';
export const DELETE_CONVERSATION_REALTIME = 'DELETE_CONVERSATION_REALTIME';
export const FETCH_GROUP_MEMBERS = 'FETCH_GROUP_MEMBERS';
export const FETCH_GROUP_MEMBERS_SUCCESS = 'FETCH_GROUP_MEMBERS_SUCCESS';
export const FETCH_GROUP_MEMBERS_FALSE = 'FETCH_GROUP_MEMBERS_FALSE';
export const FETCH_USER = 'FETCH_USER';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FALSE = 'FETCH_USER_FALSE';
export const ADD_MEMBER_REQUEST = 'ADD_MEMBER_REQUEST';
export const ADD_MEMBER_SUCCESS = 'ADD_MEMBER_SUCCESS';
export const ADD_MEMBER_FALSE = 'ADD_MEMBER_FALSE';
export const REMOVE_MEMBER_REQUEST = 'REMOVE_MEMBER_REQUEST';
export const REMOVE_MEMBER_SUCCESS = 'REMOVE_MEMBER_SUCCESS';
export const REMOVE_MEMBER_FALSE = 'REMOVE_MEMBER_FALSE';
export const LEAVE_GROUP_REQUEST = 'LEAVE_GROUP_REQUEST';
export const LEAVE_GROUP_FALSE = 'LEAVE_GROUP_FALSE';
export const LEAVE_GROUP_SUCCESS = 'LEAVE_GROUP_SUCCESS';
export const EDIT_GROUP_AVATAR_REQUEST = 'EDIT_GROUP_AVATAR_REQUEST';
export const EDIT_GROUP_AVATAR_SUCCESS = 'EDIT_GROUP_AVATAR_SUCCESS';
export const EDIT_GROUP_AVATAR_FAIL = 'EDIT_GROUP_AVATAR_FAIL';
export const TOGGLE_ACTIVATE_GROUP = 'TOGGLE_ACTIVE_GROUP';
export const TOGGLE_ACTIVATE_GROUP_SUCCESS = 'TOGGLE_ACTIVATE_GROUP_SUCCESS';
export const TOGGLE_ACTIVATE_GROUP_FAIL = 'TOGGLE_ACTIVATE_GROUP_FAIL';
export const ADD_MEMBER_REALTIME = 'ADD_MEMBER_REALTIME';
export const REMOVE_MEMBER_REALTIME = 'REMOVE_MEMBER_REALTIME';
export const LEAVE_MEMBER_REALTIME = 'LEAVE_MEMBER_REALTIME';
export const EDIT_MESSAGE_REQUEST = 'EDIT_MESSAGE_REQUEST';
export const EDIT_MESSAGE_SUCCESS = 'EDIT_MESSAGE_SUCCESS';
export const EDIT_MESSAGE_FAIL = 'EDIT_MESSAGE_FAIL';
export const DELETE_MESSAGE_REQUEST = 'DELETE_MESSAGE_REQUEST';
export const DELETE_MESSAGE_FAIL = 'DELETE_MESSAGE_FAIL';
export const DELETE_MESSAGE_SUCCESS = 'DELETE_MESSAGE_SUCCESS';
export const SET_LOADING_MESSAGE = 'SET_LOADING_MESSAGE';
export const GET_UNREAD_MESSAGES_SUCCESS = 'GET_UNREAD_MESSAGES_SUCCESS';
export const TOGGLE_NOTIFICATION = 'TOGGLE_NOTIFICATION';
export const TOGGLE_NOTIFICATION_SUCCESS = 'TOGGLE_NOTIFICATION_SUCCESS';
