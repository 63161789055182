import { NOTIFICATION_TYPES } from "src/constants";

export const getThumbnailNotification = function (notification) {
  switch (notification.type) {
    case NOTIFICATION_TYPES.NEW_QUESTION:
      return notification?.question?.topic?.icon
    case NOTIFICATION_TYPES.POST_COMMENT:
      return notification?.comment?.user?.thumbnail?.url
    case NOTIFICATION_TYPES.POST_LIKE:
      return notification?.like?.user?.thumbnail?.url
    default:
      return "";
  }
};
