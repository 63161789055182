import React from 'react';
import { PAYMENT_OPTIONS } from '../../constants';
import ItemPayment from '../ComponentItemPayment';

const PaymentList = ({ onClick }) => {
  return (
    <div className=" newChat-list-user payment-option">
      {/* <h3 className="title">{i18next.t('title_popup_payment')}</h3> */}
      {PAYMENT_OPTIONS.map((item) => (
        <div
          className="newChat-item-user"
          key={item.id}
          onClick={() => onClick(item.key)}
        >
          <ItemPayment
            className="payment_items_method"
            item={item}
            isPayment={true}
            recommended={true}
          />
        </div>
      ))}
    </div>
  );
};

export default PaymentList;
