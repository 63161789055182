export const FETCH_POST_LIST = 'FETCH_POST_LIST';
export const FETCH_POST_LIST_SUCCESS = 'FETCH_POST_LIST_SUCCESS';
export const CREATE_POST = 'CREATE_POST';
export const CREATE_POST_SUCCESS = 'CREATE_POST_SUCCESS';
export const LIKE_POST = 'LIKE_POST';
export const LIKE_POST_SUCCESS = 'LIKE_POST_SUCCESS';
export const TOOGLE_PIN_POST = 'TOOGLE_PIN_POST';
export const TOOGLE_PIN_POST_SUCCESS = 'TOOGLE_PIN_POST_SUCCESS';
export const TOOGLE_PIN_POST_FAIL = 'TOOGLE_PIN_POST_FAIL';
export const COMMENT_POST = 'COMMENT_POST';
export const COMMENT_POST_SUCCESS = 'COMMENT_POST_SUCCESS';
export const DELETE_POST = 'DELETE_POST';
export const DELETE_POST_SUCCESS = 'DELETE_POST_SUCCESS';
export const SAVE_OR_UNSAVE_POST = 'SAVE_OR_UNSAVE_POST';
export const SAVE_OR_UNSAVE_POST_SUCCESS = 'SAVE_OR_UNSAVE_POST_SUCCESS';
export const SETTING_NOTIFICATION_POST = 'SETTING_NOTIFICATION_POST';
export const SETTING_NOTIFICATION_POST_SUCCESS =
  'SETTING_NOTIFICATION_POST_SUCCESS';
export const GET_POST_BY_ID = 'GET_POST_BY_ID';
export const GET_POST_BY_ID_SUCCESS = 'GET_POST_BY_ID_SUCCESS';
export const GET_POST_NON_AUTH = 'GET_POST_NON_AUTH';
export const DELETE_COMMENT_POST = 'DELETE_COMMENT_POST';
export const DELETE_COMMENT_SUCCESS = 'DELETE_COMMENT_SUCCESS';
export const EDIT_COMMENT_POST = 'EDIT_COMMENT_POST';
export const EDIT_COMMENT_POST_SUCCESS = 'EDIT_COMMENT_POST_SUCCESS';
export const GET_COMMENT_POST_ID = 'GET_COMMENT_POST_ID';
export const GET_COMMENT_POST_ID_SUCCESS = 'GET_COMMENT_POST_ID_SUCCESS';
export const EDIT_POST = 'EDIT_POST';
export const EDIT_POST_SUCCESS = 'EDIT_POST_SUCCESS';
export const TOGGLE_POST_COMMENT = 'TOGGLE_POST_COMMENT';
