import { all, call, put, takeEvery } from 'redux-saga/effects';

import { APIClient, setAuthorization } from 'src/helpers/apiClient';
import { getAccessToken } from 'src/helpers/user';

import {
  GET_BANK_ACCOUNT_REQUEST,
  GET_TRANSACTIONS_REQUEST,
} from './constants';
import * as actions from './actions';

const get = new APIClient().get;

function* getTransactionsRequest({ payload }) {
  try {
    setAuthorization(getAccessToken());
    const response = yield call(get, 'api/transactions', {
      params: payload,
    });
    yield put(actions.getTransactionsSuccess(response.data));
  } catch (error) {
    yield put(actions.getTransactionsFaild());
  }
}

function* getBankAccountRequest() {
  try {
    setAuthorization(getAccessToken());
    const response = yield call(get, '/api/app/bank-account');
    yield put(actions.getBankAccountSucess(response.data));
  } catch (error) {
    yield put(actions.getBankAccountFailed());
    console.log(error);
  }
}

export default function* transactionsSaga() {
  yield all([
    takeEvery(GET_TRANSACTIONS_REQUEST, getTransactionsRequest),
    takeEvery(GET_BANK_ACCOUNT_REQUEST, getBankAccountRequest),
  ]);
}
