export const URL_IP_REQUEST = 'https://api.ipify.org?format=json';

export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';

export const LOGOUT_USER = 'LOGOUT_USER';

export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';

export const FORGET_PASSWORD = 'FORGET_PASSWORD';
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';

export const API_FAILED = 'AUTH_API_FAILED';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';

export const OTP_REQUEST = 'OTP_REQUEST';

export const EMAIL_USER_REGISTER = 'EMAIL_USER_REGISTER';
export const EMAIL_USER_FORGET_PASSWORD = 'EMAIL_USER_FORGET_PASSWORD';

export const RESEND_CODE = 'RESEND_CODE';
export const RESEND_CODE_FAILED = 'RESEND_CODE_FAILED';

export const SEND_CODE_RESET_PW_REQUEST = 'SEND_CODE_RESET_PW_REQUEST';
export const SEND_CODE_RESET_PW_SUCCESS = 'SEND_CODE_RESET_PW_SUCCESS';
export const SEND_CODE_RESET_PW_FAILED = 'SEND_CODE_RESET_PW_FAILED';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';
export const VERIFY_RESET_PW_SUCCESS = 'VERIFY_RESET_PW_SUCCESS';
export const VERIFY_CODE_TYPE = 'VERIFY_CODE_TYPE';
export const GET_IP_ADDRESS_REQUEST = 'GET_IP_ADDRESS_REQUEST';
export const GET_IP_ADDRESS_SUCCESS = 'GET_IP_ADDRESS_SUCCESS';
export const GET_IP_ADDRESS_FAILED = 'GET_IP_ADDRESS_FAILED';

export const KEY_IP_ADDRESS_STORAGE = 'IP_ADDRESS';
export const KEY_AUTH_USER_STORAGE = 'authUser';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const KEY_CHANGE_PASSWORD = 'KEY_CHANGE_PASSWORD';
export const KEY_LANGUAGE = 'i18nextLng';

export const UPLOAD_AVATAR_REQUEST = 'UPLOAD_AVATAR_REQUEST';
export const UPLOAD_AVATAR_SUCCESS = 'UPLOAD_AVATAR_SUCCESS';
export const UPLOAD_AVATAR_FAILED = 'UPLOAD_AVATAR_FAILED';

export const UPDATE_USER_PROFILE_REQUEST = 'UPDATE_USER_PROFILE_REQUEST';
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS';
export const UPDATE_USER_PROFILE_FAILED = 'UPDATE_USER_PROFILE_FAILED';

export const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE';
export const DELETE_ACCOUNT_REQUEST = 'DELETE_ACCOUNT_REQUEST';
export const CLEAR_ERROR = 'CLEAR_ERROR';

export const JOIN_CLUB_SUCCESS = 'JOIN_CLUB_SUCCESS';
