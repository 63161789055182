import React from 'react';

import viewIcon from 'src/assets/images/Icons/Arrow.svg';
import i18next from 'i18next';

const ItemPayment = ({ item, isPayment, recommended }) => {
  const { description, image, icon, title } = item;

  return (
    <React.Fragment>
      <div className="d-flex align-items-center">
        <div
          className={
            isPayment
              ? 'isPaymentClass user-chat-logo'
              : 'newChatClass user-chat-logo'
          }
        >
          <img src={icon ? icon : image} alt="logo" />
        </div>
      </div>
      <div className="user-chat-content">
        <React.Fragment>
          <h5>{title()}</h5>
          <p>{description()}</p>
        </React.Fragment>
      </div>
      <div className="user-view">
        <img src={viewIcon} alt="view" />
      </div>
      {recommended && (
        <div className="recommended">
          {i18next.t('recommended').toLocaleUpperCase()}
        </div>
      )}
    </React.Fragment>
  );
};
export default ItemPayment;
