import { all, call, put, fork, takeEvery } from 'redux-saga/effects';
import { APIClient, setAuthorization } from '../../helpers/apiClient';
import { getAccessToken } from '../../helpers/user';
import {
  FETCH_COURSE,
  FETCH_COURSE_DETAIL,
  FETCH_COURSE_LECTURE,
  FETCH_LECTURE,
  FETCH_COURSE_SECTIONS,
  BUY_COURSE,
  CREATE_RATE_COURSE,
  FETCH_RATE,
  CREATE_REPLY_RATE,
  EDIT_RATE,
} from './constants';
import {
  fetchCourseDetailSuccess,
  fetchCourseSectionSuccess,
  fetchCourseSuccess,
  fetchCourseLectureSuccess,
  fetchLectureSuccess,
  createRateSuccess,
  fetchRateSuccess,
  createReplyRateSuccess,
  editRateSuccess,
  fetchCourseFail,
} from './actions';

const get = new APIClient().get;
const update = new APIClient().update;
const create = new APIClient().create;

function* fetchCourse({ payload }) {
  const params = {
    page: payload.page,
    order_by: payload.order_by,
    categories: payload.categories,
    related_id: payload.related_id,
    keyword: payload.keyword,
  };
  payload.bought ? (params.bought = payload.bought) : delete params?.bought;
  params.order_by === '' && delete params?.order_by;
  params.keyword === '' && delete params?.keyword;
  const resetCourse = payload.resetCourse;
  try {
    setAuthorization(getAccessToken());
    const response = yield call(get, 'api/courses', {
      params: params,
    });
    yield put(fetchCourseSuccess({ data: response.data, resetCourse }));
  } catch (error) {
    yield put(fetchCourseFail({ data: [] }))
  }
}
function* fetchCourseDetail({ payload }) {
  try {
    setAuthorization(getAccessToken());
    const response = yield call(get, `api/courses/${payload}`);
    const params = {
      teacher_info: true,
    };
    const response_teacher = yield call(
      get,
      `api/users/${response.data.user_id}`,
      {
        params: params,
      }
    );
    const teacher = response_teacher.data;
    yield put(fetchCourseDetailSuccess({ course: response.data, teacher }));
  } catch (error) {}
}

function* fetchCourseSection({ payload }) {
  try {
    setAuthorization(getAccessToken());
    const response = yield call(
      get,
      `api/course-sections?course_id=${payload}`
    );
    yield put(fetchCourseSectionSuccess(response.data.data));
  } catch (error) {}
}

function* fetchCourseLecture({ payload }) {
  const { lectureId, courseId } = payload;
  //call api handle
  try {
    setAuthorization(getAccessToken());
    const response = yield call(
      get,
      `api/course-sections?course_id=${courseId}`
    );
    const course = yield call(get, `api/courses/${courseId}`);
    yield put(
      fetchCourseLectureSuccess({
        lectureId,
        course: course.data,
        sections: response.data.data,
      })
    );
  } catch (error) {}
  //end call api
}
function* courseBuyRequest({ payload, callback }) {
  try {
    setAuthorization(getAccessToken());
    const response = yield call(update, 'api/courses/' + payload + '/buy');
    callback(response.data.status);
  } catch (error) {
    console.log(error);
    callback(false);
  }
}

function* createRate({ payload }) {
  const { id, rating, comment, cb } = payload;
  const params = {
    rating,
    comment,
  };
  try {
    setAuthorization(getAccessToken());
    const response = yield call(update, `api/courses/${id}/rating`, params);
    yield put(createRateSuccess({ response, comment }));
    cb(response, '');
  } catch (error) {
    cb('', true);
  }
}

function* fetchRate({ payload }) {
  const params = {
    course_id: parseInt(payload.id),
    page: payload.page,
  };
  try {
    setAuthorization(getAccessToken());
    const response = yield call(get, `api/course-ratings`, {
      params: params,
    });
    yield put(
      fetchRateSuccess({
        data: response.data,
        resetRating: payload.resetRating,
      })
    );
  } catch (error) {}
}

function* fetchLecture({ payload }) {
  yield put(fetchLectureSuccess(payload));
}

function* createReply({ payload }) {
  try {
    setAuthorization(getAccessToken());
    const response = yield call(
      create,
      `api/course-ratings/${payload.parent_id}/reply`,
      {
        comment: payload.comment,
        parent_id: payload.parent_id,
      }
    );
    yield put(createReplyRateSuccess(response.data));
  } catch (error) {
    console.log(error);
  }
}
function* editRate({ payload }) {
  const { id, rating, comment, cb } = payload;
  const params = {
    rating,
    comment,
  };
  try {
    setAuthorization(getAccessToken());
    const response = yield call(update, `api/course-ratings/${id}`, params);
    yield put(editRateSuccess({ response, params: payload }));
    cb(response, '');
  } catch (error) {
    cb('', true);
  }
}

export function* watchEditRate() {
  yield takeEvery(EDIT_RATE, editRate)
}

export function* watchCreateReply() {
  yield takeEvery(CREATE_REPLY_RATE, createReply);
}

export function* watchFetchRate() {
  yield takeEvery(FETCH_RATE, fetchRate);
}

export function* watchCourseBuyRequest() {
  yield takeEvery(BUY_COURSE, courseBuyRequest);
}

export function* watchCreateRate() {
  yield takeEvery(CREATE_RATE_COURSE, createRate);
}

export function* watchfetchLecture() {
  yield takeEvery(FETCH_LECTURE, fetchLecture);
}

export function* watchFetchCourseSection() {
  yield takeEvery(FETCH_COURSE_SECTIONS, fetchCourseSection);
}

export function* watchFetchCourse() {
  yield takeEvery(FETCH_COURSE, fetchCourse);
}

export function* watchFetchCourseDetail() {
  yield takeEvery(FETCH_COURSE_DETAIL, fetchCourseDetail);
}

export function* watchfetchCourseLecture() {
  yield takeEvery(FETCH_COURSE_LECTURE, fetchCourseLecture);
}

function* courseSaga() {
  yield all([
    fork(watchFetchCourse),
    fork(watchFetchCourseDetail),
    fork(watchfetchCourseLecture),
    fork(watchfetchLecture),
    fork(watchFetchCourseSection),
    fork(watchCourseBuyRequest),
    fork(watchCreateRate),
    fork(watchFetchRate),
    fork(watchCreateReply),
    fork(watchEditRate)
  ]);
}

export default courseSaga;
