import { all, call, put, takeEvery } from 'redux-saga/effects';
import { APIClient, setAuthorization } from '../../helpers/apiClient';
import { getAccessToken } from '../../helpers/user';
import { GET_GENERAL_DATA_REQUEST, GET_CATEGORIES_COURSE } from './constants';

import * as actions from './actions';

const get = new APIClient().get;

function* getGeneralDataRequest({ payload }) {
  try {
    setAuthorization(getAccessToken());
    const response = yield call(
      get,
      'api/app/general-data?fields=topics,labels'
    );
    yield put(actions.getGeneralDataSuccess(response.data));
  } catch (error) {}
}

function* getCategoriesCourse() {
  try {
    setAuthorization(getAccessToken());
     const response = yield call(
      get,
      '/api/app/general-data?fields=course_categories'
    );
    yield put(actions.getCategoriesCourseSuccess(response.data));
  } catch (error) {}
}

export default function* generalDataSaga() {
  yield all([takeEvery(GET_GENERAL_DATA_REQUEST, getGeneralDataRequest), takeEvery(GET_CATEGORIES_COURSE, getCategoriesCourse)]);
}
