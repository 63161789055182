import config from 'src/config';

export function getAccessToken() {
  let strUser = localStorage.getItem(config.STORAGE_KEY_USER)
  let token;
  if (strUser) {
    token = JSON.parse(strUser).token;
  }
  return token;
}
