import { APIClient, setAuthorization } from 'src/helpers/apiClient';
import { PAYMENT_STRIPE_REQUEST, TRANSACTIONS_REQUEST } from './constants';
import { all, call, takeEvery } from 'redux-saga/effects';
import { getAccessToken } from 'src/helpers/user';
import config from 'src/config';

const post = new APIClient().create;

function* paymentStripeRequest({ payload, callback }) {
  const { cardNumber, cvc, expDate } = payload;

  const formData = new URLSearchParams();
  formData.append('type', 'card');
  formData.append('card[number]', cardNumber);
  formData.append('card[exp_month]', expDate.slice(0, 2));
  formData.append('card[exp_year]', '20' + expDate.slice(3, 5));
  formData.append('card[cvc]', cvc);
  try {
    setAuthorization(getAccessToken());
    const response = yield call(
      post,
      'https://api.stripe.com/v1/payment_methods',
      formData.toString(),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization:
            `Bearer ${config.STRIPE_KEY}`,
        },
      }
    );

    callback({ id: response.id });
  } catch (error) {
    callback({ error: 'Your credit card is invalid' });
  }
}

function* transactionRequest({ payload, callback }) {
  try {
    setAuthorization(getAccessToken());
    yield call(post, 'api/transactions', payload);

    callback();
  } catch (error) {}
}

export default function* paymentSaga() {
  yield all([
    takeEvery(PAYMENT_STRIPE_REQUEST, paymentStripeRequest),
    takeEvery(TRANSACTIONS_REQUEST, transactionRequest),
  ]);
}
