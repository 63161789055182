import { all } from 'redux-saga/effects';
import authSaga from './auth/saga';
import chatSaga from './chat/saga';
import usersSaga from './users/saga';
import questionsSaga from './questions/saga';
import notificationsSaga from './notifications/saga';
import transactionsSaga from './transactions/saga';
import generalDataSaga from './masterData/saga';
import paymentDataSaga from './payment/saga';
import communitySaga from './communities/saga';
import courseSaga from './course/saga';

export default function* rootSaga(getState) {
  yield all([
    authSaga(),
    chatSaga(),
    usersSaga(),
    questionsSaga(),
    notificationsSaga(),
    transactionsSaga(),
    generalDataSaga(),
    paymentDataSaga(),
    communitySaga(),
    courseSaga(),
  ]);
}
