import React, { useEffect } from 'react';
import Routes from './routes/';

//Import Scss
import './assets/scss/themes.scss';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { LANGUAGE } from './pages/Library/constants';
import { getLangBrowser } from './helpers/getLangBrowser';
import { InitAnalytic } from './services/firebaseService';

function App() {
  const { i18n } = useTranslation();
  const auth = useSelector((state) => state.Auth.user);
  const languageDefault = auth?.user?.language;

  useEffect(() => {
    InitAnalytic();
    i18n.changeLanguage(languageDefault || getLangBrowser() || LANGUAGE.VI);
  }, [i18n, languageDefault]);

  return <Routes />;
}
export default App;
