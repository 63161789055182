import React from 'react';

import logoPombee from '../../assets/images/header_logo.png';
import club_join from '../../assets/images/club_join.png';
import club_member from '../../assets/images/club_member.png';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  fetchAllConversations,
  fetchMessages,
  getEnglishClub,
} from 'src/redux/actions';
import { TYPE_MEMBER } from 'src/constants';

const HeaderPage = function () {
  const history = useHistory();
  const disPatch = useDispatch();
  const auth = useSelector((state) => state.Auth);
  const isAdmin = auth?.user?.user.role === TYPE_MEMBER.ADMIN;
  const isJoinedClub =
    auth?.isJoinedClub || auth?.user?.user?.english_club_conversation;
  const activeTab = history.location.pathname;
  const handleClickClub = () => {
    history.push({
      pathname: '/chat',
      isEnglishClub: true,
    });
    if (activeTab.includes('/chat')) {
      disPatch(fetchAllConversations());
      disPatch(getEnglishClub());
      disPatch(
        fetchMessages({
          conversation_id: auth?.user?.user?.english_club_conversation_id,
          currentPage: 1,
        })
      );
    }
  };
  return (
    <>
      <div className="header_page ">
        <div className="circle_contai">
          <div className="header_logo_pombeebee">
            <img src={logoPombee} alt="Pombeebee" />
          </div>
        </div>
        {!isAdmin && (
          <div className="wrapper_join_club">
            {isJoinedClub ? (
              <button
                className="common_icon_button d-flex align-items-center"
                style={{ border: 'unset', padding: '8px 18px' }}
                onClick={() => handleClickClub()}
              >
                <img
                  className="left_button"
                  src={club_member}
                  alt=""
                  style={{ width: '30px' }}
                />
                <span className="right_button">{i18next.t('member_club')}</span>
              </button>
            ) : (
              <button
                className="common_icon_button d-flex align-items-center"
                style={{ border: 'unset', padding: '8px 18px' }}
                onClick={() => handleClickClub()}
              >
                <img
                  className="left_button"
                  src={club_join}
                  alt=""
                  style={{ width: '30px' }}
                />
                <span className="right_button">{i18next.t('join_club')}</span>
              </button>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default HeaderPage;
