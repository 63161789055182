import i18next from 'i18next';
import { PAYMENT_STATUS } from 'src/constants';
import { TRANSACTION_MODEL } from 'src/pages/Transactions/constants';
const TO_RADIANS = Math.PI / 180;

export const getTransactionTypeText = (type, transferred_amount) => {
  let typeText = '';
  switch (type) {
    case TRANSACTION_MODEL.TOPUP:
      typeText = i18next.t('purchased_beebux');
      break;
    case TRANSACTION_MODEL.UNLOCK_ANSWER:
      typeText = i18next.t('unlock_answer');
      break;
    case TRANSACTION_MODEL.BUY_CHARACTER:
      typeText = i18next.t('transferred_amount', {
        amount: transferred_amount,
      });

      break;
    default:
      break;
  }
  return typeText;
};

export const convertStatusTransaction = (status) => {
  let statusText = '';
  switch (status) {
    case PAYMENT_STATUS.CANCELLED:
      statusText = i18next.t('cancelled');
      break;
    case PAYMENT_STATUS.PENDING:
      statusText = i18next.t('pending');
      break;
    case PAYMENT_STATUS.PAIUNPAIDD:
      statusText = i18next.t('unpaid');
      break;

    default:
      statusText = i18next.t('completed');
      break;
  }
  return statusText;
};

export const getBrowser = () => {
  // Retrieve the browser information
  const browserInfo = {
    name: 'Unknown',
    version: 'Unknown',
  };
  if (navigator.userAgent) {
    // Use the user agent string to extract browser information
    const userAgent = navigator.userAgent.toLowerCase();
    if (/msie|trident/.test(userAgent)) {
      browserInfo.name = 'Internet Explorer';
      browserInfo.version = userAgent.match(/(msie |rv:)(\d+(\.\d+)?)/i)[2];
    } else if (/firefox/.test(userAgent)) {
      browserInfo.name = 'Mozilla Firefox';
      browserInfo.version = userAgent.match(/firefox\/(\d+(\.\d+)?)/i)[1];
    } else if (/chrome/.test(userAgent)) {
      browserInfo.name = 'Google Chrome';
      browserInfo.version = userAgent.match(/chrome\/(\d+(\.\d+)?)/i)[1];
    } else if (/safari/.test(userAgent)) {
      browserInfo.name = 'Safari';
      browserInfo.version = userAgent.match(/version\/(\d+(\.\d+)?)/i)[1];
    } else if (/opera/.test(userAgent)) {
      browserInfo.name = 'Opera';
      browserInfo.version = userAgent.match(/version\/(\d+(\.\d+)?)/i)[1];
    }
  }
  // Log the device ID and browser information
  return browserInfo;
};

export const getLocalStorage = (key) => {
  return JSON.parse(localStorage.getItem(key));
};

export const setLocalStorage = (key, value) => {
  return localStorage.setItem(key, JSON.stringify(value));
};

export const numberWithCommas = (value) => {
  if (!value) return '0';
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const isEqual = (obj1, obj2) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};

export function getCroppedImg(image, crop, fileName, scale = 1) {
  const canvas = document.createElement('canvas');
  let rotate = 0;
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('No 2d context');
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const pixelRatio = window.devicePixelRatio;

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = 'high';

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  const rotateRads = rotate * TO_RADIANS;
  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx.save();

  ctx.translate(-cropX, -cropY);
  ctx.translate(centerX, centerY);
  ctx.rotate(rotateRads);
  ctx.scale(scale, scale);
  ctx.translate(-centerX, -centerY);
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight
  );

  ctx.restore();
  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        const file = new File([blob], fileName, { type: 'image/jpeg' });
        resolve(file);
      },
      'image/jpeg',
      0.95
    );
  });
}
