import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  API_FAILED,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  EMAIL_USER_REGISTER,
  SEND_CODE_RESET_PW_SUCCESS,
  SEND_CODE_RESET_PW_FAILED,
  VERIFY_RESET_PW_SUCCESS,
  VERIFY_CODE_TYPE,
  GET_IP_ADDRESS_SUCCESS,
  GET_IP_ADDRESS_FAILED,
  GET_IP_ADDRESS_REQUEST,
  KEY_IP_ADDRESS_STORAGE,
  UPLOAD_AVATAR_SUCCESS,
  UPLOAD_AVATAR_REQUEST,
  UPLOAD_AVATAR_FAILED,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_REQUEST,
  UPDATE_USER_PROFILE_FAILED,
  CLEAR_ERROR,
  SEND_CODE_RESET_PW_REQUEST,
  JOIN_CLUB_SUCCESS,
} from './constants';

import { getLoggedInUser } from '../../helpers/authUtils';
import { setLocalStorage } from 'src/helpers';

const INIT_STATE = {
  user: getLoggedInUser(),
  loading: false,
  isFetchingUser: false,
  verifyCode: '',
  messages: {},
  isJoinedClub: false,
};

const Auth = (state = INIT_STATE, action) => {
  switch (action.type) {
    case JOIN_CLUB_SUCCESS: {
      return {
        ...state,
        isJoinedClub: true,
      };
    }
    case LOGIN_USER:
      return { ...state, loading: true };
    case LOGIN_USER_SUCCESS:
      return { ...state, user: action.payload, loading: false, error: null };

    case REGISTER_USER:
      return { ...state, loading: true };
    case REGISTER_USER_SUCCESS:
      localStorage.setItem(
        EMAIL_USER_REGISTER,
        JSON.stringify(action.payload.data.user.email)
      );
      localStorage.setItem(VERIFY_CODE_TYPE, JSON.stringify(REGISTER_USER));
      return {
        ...state,
        user: action.payload.data.user,
        loading: false,
        error: null,
      };

    case LOGOUT_USER:
      return { ...state, user: null };

    case FORGET_PASSWORD:
      return { ...state, loading: true };

    case FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordResetStatus: action.payload,
        loading: false,
        error: null,
      };

    case API_FAILED:
      return { ...state, loading: false, error: action.payload };

    case UPDATE_USER:
      return { ...state, user: { ...state.user, ...action.payload } };

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          user: { ...state.user.user, ...action.payload },
        },
      };

    case SEND_CODE_RESET_PW_REQUEST:
      return { ...state, loading: true };

    case SEND_CODE_RESET_PW_SUCCESS:
      localStorage.setItem(VERIFY_CODE_TYPE, JSON.stringify(FORGET_PASSWORD));
      return { ...state, messages: { success: true }, loading: false };

    case SEND_CODE_RESET_PW_FAILED:
      return { ...state, messages: { error: true }, loading: false };

    case VERIFY_RESET_PW_SUCCESS:
      const verifyCode = action.payload;
      return { ...state, verifyCode };

    case GET_IP_ADDRESS_REQUEST:
      return { ...state, loading: true };

    case GET_IP_ADDRESS_SUCCESS:
      const ip = action.payload;
      setLocalStorage(KEY_IP_ADDRESS_STORAGE, ip);
      return { ...state, loading: false };

    case GET_IP_ADDRESS_FAILED:
      return { ...state, loading: false };

    case UPLOAD_AVATAR_REQUEST:
      return { ...state, isFetchingUser: true };

    case UPLOAD_AVATAR_SUCCESS:
      const user = action.payload;
      return {
        ...state,
        isFetchingUser: false,
        user,
      };

    case UPLOAD_AVATAR_FAILED:
      return { ...state, isFetchingUser: false };

    case UPDATE_USER_PROFILE_REQUEST:
      return { ...state, isFetchingUser: true };

    case UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        isFetchingUser: false,
        user: { ...action.payload },
      };

    case UPDATE_USER_PROFILE_FAILED:
      return { ...state, isFetchingUser: false };

    case CLEAR_ERROR:
      return { ...state, error: '' };

    default:
      return { ...state };
  }
};

export default Auth;
