import {
  GET_TRANSACTIONS_REQUEST,
  GET_TRANSACTIONS_FAILD,
  GET_TRANSACTIONS_SUCCESS,
  GET_BANK_ACCOUNT_REQUEST,
  GET_BANK_ACCOUNT_SUCCESS,
  GET_BANK_ACCOUNT_FAILED,
} from '../transactions/constants';

export const getTransactionsRequest = (payload) => ({
  type: GET_TRANSACTIONS_REQUEST,
  payload,
});

export const getTransactionsSuccess = (payload) => ({
  type: GET_TRANSACTIONS_SUCCESS,
  payload,
});

export const getTransactionsFaild = () => ({
  type: GET_TRANSACTIONS_FAILD,
});

export const getBankAccountRequest = () => ({
  type: GET_BANK_ACCOUNT_REQUEST,
});

export const getBankAccountSucess = (payload) => ({
  type: GET_BANK_ACCOUNT_SUCCESS,
  payload,
});

export const getBankAccountFailed = () => ({
  type: GET_BANK_ACCOUNT_FAILED,
});
