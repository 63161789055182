import {
  CONVERSATION_STATUS,
  CONVERSATION_TYPE,
  MESSAGE_TYPE,
} from 'src/constants';
import emptyAvatar from '../assets/images/users/avatar-empty.png';
import avatarGroup from '../assets/images/Icons/chat_group.png';
import i18next from 'i18next';
import { formatDate } from './renderGroup';
import { formatDateTime } from './formatTime';

export const datetimeConverter = (time) => {
  if (time === undefined) {
    return 'Just now';
  }
  let date = new Date(time);
  let hour = String(date.getHours()).padStart(2, '0');
  let minute = String(date.getMinutes()).padStart(2, '0');
  let selectedYear = date.getFullYear();
  let selectedMonth = String(date.getMonth() + 1).padStart(2, '0');
  let selectedDay = String(date.getDate()).padStart(2, '0');

  return `${hour}:${minute} ${selectedDay}-${selectedMonth}-${selectedYear}`;
};

export function chatParser(conversation) {
  let record = {
    id: conversation.id,
    type: conversation.type,
    botType: conversation.bot_type,
    isChatbot: conversation.type === CONVERSATION_TYPE.TYPE_BOT,
    isHelpCenter: conversation.type === CONVERSATION_TYPE.TYPE_HELP_CENTER,
    status:
      conversation.type === CONVERSATION_TYPE.TYPE_BOT ? 'online' : 'away',
    createdBy: conversation.created_by,
    sortedTime: conversation.last_message
      ? conversation.last_message.created_at
      : conversation.created_at,
    lastMessage: messageParser(conversation.last_message),
    unRead: getUnreadNumber(conversation),
    name: getConversationName(conversation),
    profilePicture: getConversationPicture(conversation),
    show_input: conversation.show_input,
    is_special: conversation.is_special,
    has_voice: conversation.has_voice || conversation.full_options,
    members: conversation.members,
    me: conversation.me,
    isActivate:
      conversation.status === CONVERSATION_STATUS.ACTIVE ? true : false,
    statusMe: conversation.status,
  };
  record.messages = [];
  return record;
}

export function convertPostMessage(newMessage) {
  return {
    conversation_id: newMessage.conversation_id,
    message: newMessage.message,
    type: 'TEXT',
  };
}

export function messageParser(m) {
  if (m == null) {
    return null;
  }
  let item = {
    id: m.id,
    conversation_id: m.conversation_id,
    message: m.message,
    created_at: m.created_at,
    time: datetimeConverter(m.created_at),
    isImageMessage:
      m.type === MESSAGE_TYPE.ATTACHMENT &&
      m.attachment_type === MESSAGE_TYPE.IMAGE,
    isFileMessage:
      (m.type === MESSAGE_TYPE.ATTACHMENT ||
        m.type === MESSAGE_TYPE.READING_LISTENING ||
        m.type === MESSAGE_TYPE.READING ||
        m.type === MESSAGE_TYPE.LISTENING) &&
      m.attachment_type !== MESSAGE_TYPE.IMAGE,
    isFileDocument:
      m.type === MESSAGE_TYPE.ATTACHMENT &&
      m.attachment_type === MESSAGE_TYPE.DOCUMENT,
    userId: m.user_id,
    sender: m.sender,
    isRead: m.status === 'READ' ? true : false,
    isVocabulary: m.type === MESSAGE_TYPE.VOCABULARY || false,
    vocabularies: m.vocabularies,
    questions: m.questions,
    isTypeReading: m.type === MESSAGE_TYPE.READING,
    isTypeListening: m.type === MESSAGE_TYPE.LISTENING,
    isTypeSystem: m.type === MESSAGE_TYPE.SYSTEM,
  };
  if (m.options) {
    item.messageOptions = m.options;
  }
  if (item.isImageMessage) {
    item.imageMessage = [{ image: m.attachment_url }];
  }
  if (item.isFileMessage) {
    if (item.message) {
      var res = item.message?.split('/');
      item.message = res[res.length - 1];
    }
    item.fileMessage = m.attachment_url;
  }
  return item;
}

function getUnreadNumber(conversation) {
  if (conversation.me != null) {
    return conversation.me.number_unread;
  }
  return 0;
}

function getConversationName(conversation) {
  switch (conversation.type) {
    case 'HELP_CENTER':
      return i18next.t('help_center');
    case 'BOT':
      if (conversation.name !== '') {
        return conversation.name;
      } else {
        return 'Pombeebee - ChatGPT';
      }
    default:
      if (conversation.name !== '') {
        return conversation.name;
      }
      if (
        conversation.members &&
        conversation.members.length > 0 &&
        conversation.members[0].user
      ) {
        return conversation.members[0].user.name;
      }
      break;
  }
  return undefined;
}

function getConversationPicture(conversation) {
  if (conversation.type !== 'HELP_CENTER' && conversation.type !== 'BOT') {
    if (conversation.type === CONVERSATION_TYPE.GROUP) {
      if (conversation.icon) {
        return conversation.icon;
      }
      return avatarGroup;
    } else if (
      conversation.members &&
      conversation.members.length > 0 &&
      conversation.members[0].user
    ) {
      if (conversation.members[0].user.thumbnail) {
        return conversation.members[0].user.thumbnail.url;
      }
    }
  }
  if (conversation.icon !== null) {
    return conversation.icon;
  }
  return emptyAvatar;
}

export function sortConversations(conversations) {
  let items = [];
  if (conversations) {
    for (let index = 0; index < conversations.length; index++) {
      conversations[index].formatedDate = formatDate(
        formatDateTime(conversations[index].sortedTime)
      );
      var currentItem = conversations[index];
      currentItem.showDate = null;
      if (index === 0) {
        currentItem.showDate = formatDate(
          formatDateTime(currentItem.sortedTime)
        );
      } else {
        if (
          currentItem.formatedDate !== conversations[index - 1].formatedDate
        ) {
          currentItem.showDate = formatDate(
            formatDateTime(currentItem.sortedTime)
          );
        }
      }
      items.push(currentItem);
    }
  }
  return items;
}
export const memberParser = (m) => {
  const member = {
    id: m.id,
    user_id: m.user_id || m.user?.id,
    type: m.type || 'MEMBER',
    email: m.email || m.user?.email,
    thumbnail: m.thumbnail || m.user?.thumbnail,
    user: m.user,
    name: m.name || m.user?.name,
    hasNotification: m.has_notification,
  };
  return member;
};
