import { all, call, put, takeEvery } from 'redux-saga/effects';
import { APIClient, setAuthorization } from '../../helpers/apiClient';
import { getAccessToken } from '../../helpers/user';
import { GET_USER_WALLET_REQUEST } from './constants';

import * as actions from './actions';

const get = new APIClient().get;

function* getUserWalletRequest({ payload }) {
  try {
    setAuthorization(getAccessToken());
    const response = yield call(get, 'api/users/wallet');

    yield put(actions.getUserWalletSuccess(response.data));
  } catch (error) {}
}

export default function* usersSaga() {
  yield all([takeEvery(GET_USER_WALLET_REQUEST, getUserWalletRequest)]);
}
