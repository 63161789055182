import {
  GET_TRANSACTIONS_REQUEST,
  GET_TRANSACTIONS_SUCCESS,
  GET_TRANSACTIONS_FAILD,
  GET_BANK_ACCOUNT_SUCCESS,
} from './constants';

const INIT_STATE = {
  transactions: [],
  processing: false,
  last_page: 0,
  listMethodPayment: {},
};

const Transactions = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TRANSACTIONS_REQUEST: {
      return {
        ...state,
        processing: true,
      };
    }
    case GET_TRANSACTIONS_SUCCESS: {
      const { data, last_page, current_page } = action.payload;
      return {
        ...state,
        transactions:
          current_page === 1 ? data : [...state.transactions, ...data],
        processing: false,
        last_page,
      };
    }
    case GET_TRANSACTIONS_FAILD: {
      return {
        ...state,
        processing: false,
      };
    }
    case GET_BANK_ACCOUNT_SUCCESS: {
      const {
        acc_bank,
        acc_momo,
        acc_zalo,
        qr_code_bank,
        qr_code_momo,
        qr_code_zalo,
      } = action.payload;
      const _listMethodPayment = {
        CREDIT_CARD: {},
        BANK_TRANSFER: {
          title: 'Bank transfer',
          imgUrl: qr_code_bank,
          phone: acc_bank,
        },
        MOMO: {
          title: 'Momo transfer',
          imgUrl: qr_code_momo,
          phone: acc_momo,
        },
        ZALOPAY: {
          title: 'ZaloPay transfer',
          imgUrl: qr_code_zalo,
          phone: acc_zalo,
        },
      };
      return {
        ...state,
        listMethodPayment: _listMethodPayment,
      };
    }

    default:
      return { ...state };
  }
};

export default Transactions;
