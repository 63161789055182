import React from 'react';
export function groupChatsByDate(recentChatList) {
  return recentChatList.reduce((groups, chat) => {
    const lastMessageDate = chat.lastMessage
      ? chat.lastMessage.time.split(' ')[1]
      : '';
    if (groups[lastMessageDate]) {
      groups[lastMessageDate].push(chat);
    } else {
      groups[lastMessageDate] = [chat];
    }
    return groups;
  }, {});
}

export function formatDate(dateString) {
  const currentDate = new Date();
  const [date, month, year] = dateString.split('-');
  const inputDate = new Date(`${month}-${date}-${year}`);

  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const currentDay = currentDate.getDate();

  const inputYear = inputDate.getFullYear();
  const inputMonth = inputDate.getMonth();
  const inputDay = inputDate.getDate();

  if (
    currentYear === inputYear &&
    currentMonth === inputMonth &&
    currentDay === inputDay
  ) {
    return 'Today';
  } else if (
    currentYear === inputYear &&
    currentMonth === inputMonth &&
    currentDay - inputDay === 1
  ) {
    return 'Yesterday';
  }

  return dateString;
}
const htmlEntities = (string) => {
  let value = string?.replace(/[\u00A0-\u9999<>]/gim, function (i) {
    return '&#' + i.charCodeAt(0) + ';';
  });
  return value;
};
export const renderTextLinkContent = (text) => {
  const displayTextContent = (string) => {
    let urlPattern = /\bhttps?:\/\/\S+/gi;
    string = htmlEntities(string);
    string = string.replace(urlPattern, (url) => {
      return `<a class="message-link" target="_blank" href="${url}">${url}</a>`;
    });
    return { __html: string };
  };

  const convertedText = htmlEntities(text);
  return <span style={{display: "inline-block"}} className='w-100' dangerouslySetInnerHTML={displayTextContent(convertedText)} />;
};
