import {
  GET_USER_WALLET_REQUEST,
  GET_USER_WALLET_SUCCESS,
  GET_USER_WALLET_FAILD,
} from './constants';

export const getUserWalletRequest = (payload) => {
  return {
    type: GET_USER_WALLET_REQUEST,
    payload,
  };
};

export const getUserWalletSuccess = (payload) => ({
  type: GET_USER_WALLET_SUCCESS,
  payload,
});

export const getUserWalletFaild = () => ({
  type: GET_USER_WALLET_FAILD,
});
