import {
  ACTIVE_CONVERSATION,
  FULL_CONVERSATION,
  FULL_CONVERSATION_SUCCESS,
  MAKE_AS_READ,
  GET_NEW_CONVERSATION,
  GET_NEW_CONVERSATION_SUCCESS,
  CREATE_CHAT,
  CREATE_CHAT_SUCCESS,
  SEEN_MESSAGE,
  LOAD_MORE_CONVERSATION,
  LOAD_MORE_CONVERSATION_SUCCESS,
  FETCH_MESSAGE_SUCCESS,
  ADD_MESSAGE,
  FETCHING_MESSAGE_REQUEST,
  FETCHING_MESSAGE_SUCCESS,
  FETCHING_MESSAGE_FAILED,
  GET_HELP_CENTER_SUCCESS,
  GET_ENGLISH_CLUB_SUCCESS,
  TOGGLE_HELPCENTER,
  RENAME_CONVERSATION_SUCCESS,
  DELETE_CONVERSATION_SUCCESS,
  GET_CHAT_BOT_TYPE_SUCCESS,
  ADD_CONVERSATION_REALTIME,
  UPDATE_CONVERSATION_REALTIME,
  DELETE_CONVERSATION_REALTIME,
  FETCH_GROUP_MEMBERS_SUCCESS,
  FETCH_USER_SUCCESS,
  FETCH_USER_FALSE,
  ADD_MEMBER_SUCCESS,
  REMOVE_MEMBER_SUCCESS,
  LEAVE_GROUP_SUCCESS,
  FETCH_USER,
  TOGGLE_ACTIVATE_GROUP_SUCCESS,
  TOGGLE_ACTIVATE_GROUP_FAIL,
  ADD_MEMBER_REALTIME,
  REMOVE_MEMBER_REALTIME,
  LEAVE_MEMBER_REALTIME,
  EDIT_MESSAGE_REQUEST,
  EDIT_MESSAGE_SUCCESS,
  EDIT_MESSAGE_FAIL,
  DELETE_MESSAGE_SUCCESS,
  DELETE_MESSAGE_FAIL,
  SET_LOADING_MESSAGE,
  GET_UNREAD_MESSAGES_SUCCESS,
  TOGGLE_NOTIFICATION_SUCCESS,
  TOGGLE_ENGLISHCLUB,
} from './constants';

import { memberParser, sortConversations } from 'src/helpers/chatParser';
import { getLoggedInUser } from 'src/helpers/authUtils';
import { CONVERSATION_STATUS, CONVERSATION_TYPE } from 'src/constants';
const strUser = getLoggedInUser();

const INIT_STATE = {
  user: strUser?.user,
  activeConversationIndex: 0,
  new_conversation: '',
  next_conversation_page: 1,
  is_load_conversation: false,
  can_load_conversation: true,
  conversations: null,
  selected_edit_msg: {
    id: null,
    content: null,
  },
  isFetchingMessages: [],
  helpCenter: {},
  englishClub: {},
  newChat: null,
  listBotType: [],
  isConversationActiveAdd: false,
  members: [],
  listUsers: [],
  isUserFetching: false,
  isUserFetched: false,
  lastPage: 1,
  currentPage: 1,
  isLoadingMessage: false,
  lastPageConversations: 1,
  unReadMessages: 0,
  isSearch: false,
};

const Chat = (state = INIT_STATE, action) => {
  let listChat = [];
  switch (action.type) {
    case TOGGLE_HELPCENTER: {
      state.helpCenter.toggleModal = action.payload;
      return {
        ...state,
      };
    }
    case GET_ENGLISH_CLUB_SUCCESS:
      const newEnglish = { ...action.payload };
      newEnglish.toggleEnglish = true;
      const newConvers = [...state.conversations].filter(
        (con) => con.id !== newEnglish.id
      );
      const newConversations = sortConversations([newEnglish, ...newConvers]);
      return {
        ...state,
        englishClub: newEnglish,
        conversations: newConversations,
      };
    case GET_CHAT_BOT_TYPE_SUCCESS: {
      return {
        ...state,
        listBotType: action.payload,
      };
    }
    case GET_UNREAD_MESSAGES_SUCCESS: {
      return {
        ...state,
        unReadMessages: action.payload,
      };
    }

    case ACTIVE_CONVERSATION: {
      const updateMember2 = state.conversations?.[action.payload]?.members;
      let memberParsers = [];
      if (updateMember2) {
        updateMember2.forEach((mem) => {
          memberParsers.push(memberParser(mem));
        });
      }
      return {
        ...state,
        lastPage: 1,
        currentPage: 1,
        activeConversationIndex: action.payload,
        members: memberParsers,
      };
    }

    case FULL_CONVERSATION: {
      return {
        ...state,
        lastPage: 1,
      };
    }

    case FULL_CONVERSATION_SUCCESS:
      listChat = action.payload.listChat;
      const isFetchingMessages = listChat.map(() => false);
      const updateMember1 = listChat[state.activeConversationIndex]?.members;
      let memberParsers2 = [];
      if (updateMember1) {
        updateMember1.forEach((mem) => {
          memberParsers2.push(memberParser(mem));
        });
      }
      if (state.conversations?.length > 0 && listChat.length > 0) {
        listChat[state.activeConversationIndex].messages =
          state.conversations[state.activeConversationIndex]?.messages;
      }
      if (state.englishClub.toggleEnglish) {
        const newListChat = [...listChat].filter(
          (chat) => chat.id !== state.englishClub.id
        );
        listChat = [state.englishClub, ...newListChat];
      }
      listChat = sortConversations(listChat);
      return {
        ...state,
        conversations: listChat,
        next_conversation_page: action.payload.current_page
          ? ++action.payload.current_page
          : ++state.next_conversation_page,
        isFetchingMessages: isFetchingMessages,
        members: memberParsers2,
        lastPageConversations: action.payload?.lastPage,
        isSearch: action.payload.search ? !state.isSearch : state.isSearch,
      };
    case CREATE_CHAT:
      return {
        ...state,
      };

    case CREATE_CHAT_SUCCESS:
      listChat = sortConversations([action.payload, ...state.conversations]);
      const updateMember3 = listChat[0]?.members;
      let memberParsers3 = [];
      if (updateMember3) {
        updateMember3.forEach((mem) => {
          memberParsers3.push(memberParser(mem));
        });
      }
      return {
        ...state,
        activeConversationIndex: 0,
        conversations: listChat,
        members: memberParsers3,
      };
    case GET_NEW_CONVERSATION:
      return {
        ...state,
        conversations: [...state.conversations],
        new_conversation: '',
      };
    case GET_NEW_CONVERSATION_SUCCESS:
      let active = state.activeConversationIndex;
      listChat = sortConversations([action.payload, ...state.conversations]);
      return {
        ...state,
        conversations: listChat,
        activeConversationIndex: ++active,
      };

    case MAKE_AS_READ:
      const conversation_id = action.payload;
      let newUnReadMessages = state.unReadMessages;
      if (conversation_id === state.helpCenter.id) {
        // If it's the help center conversation, set unRead to 0
        return {
          ...state,
          helpCenter: {
            ...state.helpCenter,
            unRead: 0,
          },
        };
      }

      // For other conversations, find the one with the specified conversation_id and set unRead to 0
      const updatedConversations = state.conversations.map((conversation) => {
        if (conversation.id === conversation_id) {
          if (
            conversation.type === CONVERSATION_TYPE.GROUP &&
            state.unReadMessages > 0
          ) {
            newUnReadMessages = newUnReadMessages - 1;
          }
          return {
            ...conversation,
            unRead: 0,
          };
        }
        return conversation;
      });

      return {
        ...state,
        conversations: updatedConversations,
        unReadMessages: newUnReadMessages,
      };

    case SEEN_MESSAGE:
      for (let index = 0; index < state.conversations.length; index++) {
        if (state.conversations[index].id === action.payload) {
          for (
            let index2 = 0;
            index2 < state.conversations[index].messages.length;
            index2++
          ) {
            state.conversations[index].messages[index2].isRead = true;
          }
        }
      }
      return {
        ...state,
        conversations: [...state.conversations],
      };
    case LOAD_MORE_CONVERSATION:
      return {
        ...state,
        is_load_conversation: true,
      };
    case LOAD_MORE_CONVERSATION_SUCCESS:
      if (action.payload.listChat?.length === 0) {
        return {
          ...state,
          can_load_conversation: false,
          is_load_conversation: false,
          next_conversation_page: action.payload.current_page
            ? ++action.payload.current_page
            : ++state.next_conversation_page,
        };
      }
      listChat = sortConversations([
        ...state.conversations.concat(action.payload.listChat),
      ]);
      return {
        ...state,
        conversations: listChat,
        is_load_conversation: false,
        next_conversation_page: action.payload.current_page
          ? ++action.payload.current_page
          : ++state.next_conversation_page,
      };

    case FETCH_MESSAGE_SUCCESS:
      state.lastPage = action.payload.lastPage;
      state.currentPage = action.payload.currentPage;
      if (action.payload.messages?.length !== 0) {
        const newMessages = action.payload.messages?.reverse();
        if (action.payload.conversation_id === state.helpCenter.id) {
          return {
            ...state,
            helpCenter: {
              ...state.helpCenter,
              messages:
                action.payload.currentPage === 1
                  ? [...newMessages]
                  : [...state.helpCenter.messages, ...newMessages],
            },
          };
        }
        let foundIndex2;
        let listConversations2 = state.conversations;
        for (const key in listConversations2) {
          if (listConversations2[key].id === action.payload.conversation_id) {
            foundIndex2 = key;
          }
        }
        if (foundIndex2 !== undefined) {
          listConversations2[foundIndex2].messages =
            action.payload.currentPage === 1
              ? [...newMessages]
              : [...listConversations2[foundIndex2].messages, ...newMessages];
        }
        return {
          ...state,
          conversations: [...listConversations2],
          isConversationActiveAdd: false,
        };
      }
      const conversations = [...state.conversations].map((x) => {
        if (x.id === action.payload.conversation_id) {
          return {
            ...x,
            messages: [],
          };
        }
        return x;
      });
      return {
        ...state,
        conversations,
        isConversationActiveAdd: false,
      };

    case ADD_MESSAGE: {
      const isAddMessageActive =
        action.payload?.isFileMessage &&
        state.conversations[state.activeConversationIndex]?.id ===
          action.payload?.conversation_id;
      let comingMessage = action.payload;
      if (comingMessage.conversation_id === state.helpCenter.id) {
        if (
          state.helpCenter.messages === null ||
          state.helpCenter.messages === undefined
        ) {
          state.helpCenter.messages = [];
        }
        let foundMessage = false;
        for (let ids = 0; ids < state.helpCenter.messages.length; ids++) {
          let loopMessage = state.helpCenter.messages[ids];
          if (
            loopMessage.userId === comingMessage.userId &&
            loopMessage.id === undefined
          ) {
            state.helpCenter.messages[ids] = comingMessage;
            foundMessage = true;
          }
          if (loopMessage.id === comingMessage.id) {
            foundMessage = true;
          }
        }
        if (!foundMessage) {
          state.helpCenter.messages.unshift(comingMessage);
          state.helpCenter.lastMessage = comingMessage;
        }
        return {
          ...state,
          helpCenter: { ...state.helpCenter },
        };
      }
      let newUnReadMessages = state.unReadMessages;
      let foundIndex3;
      let listConversations3 = [...state.conversations];
      let newActiveIndex = state.activeConversationIndex;
      let tempMessages = [];
      for (const key in listConversations3) {
        if (listConversations3[key].id === comingMessage.conversation_id) {
          foundIndex3 = key;
        }
      }
      if (foundIndex3 !== undefined) {
        if (
          listConversations3[foundIndex3].messages === null ||
          listConversations3[foundIndex3].messages === undefined
        ) {
          listConversations3[foundIndex3].messages = [];
        }
        let lenMessages = listConversations3[foundIndex3].messages.length;
        let foundMessage = false;
        for (let ids = 0; ids < lenMessages; ids++) {
          let loopMessage = listConversations3[foundIndex3].messages[ids];
          if (
            loopMessage.userId === comingMessage.userId &&
            loopMessage.id === undefined
          ) {
            listConversations3[foundIndex3].messages[ids] = comingMessage;
            foundMessage = true;
          }
          if (loopMessage.id === comingMessage.id) {
            foundMessage = true;
          }
        }
        if (!foundMessage) {
          listConversations3[foundIndex3].messages.unshift(comingMessage);
        }
        if (
          parseInt(foundIndex3) !== parseInt(state.activeConversationIndex) &&
          state.user?.id !== comingMessage.userId
        ) {
          if (
            listConversations3[foundIndex3]?.unRead === 0 &&
            listConversations3[foundIndex3].type !== CONVERSATION_TYPE.TYPE_BOT
          ) {
            newUnReadMessages += 1;
          }
          listConversations3[foundIndex3].unRead += 1;
        }
        listConversations3[foundIndex3].lastMessage = comingMessage;
        if (parseInt(foundIndex3) === parseInt(state.activeConversationIndex)) {
          tempMessages = listConversations3[foundIndex3].messages;
          listConversations3.unshift(
            listConversations3.splice(foundIndex3, 1)[0]
          );
        } else {
          listConversations3.unshift(
            listConversations3.splice(foundIndex3, 1)[0]
          );
          tempMessages = listConversations3[foundIndex3].messages;
        }

        if (parseInt(foundIndex3) === parseInt(state.activeConversationIndex)) {
          newActiveIndex = 0;
          listConversations3[newActiveIndex].messages = tempMessages;
        } else if (
          parseInt(foundIndex3) > parseInt(state.activeConversationIndex)
        ) {
          newActiveIndex = parseInt(state.activeConversationIndex + 1);
        } else {
          newActiveIndex = parseInt(state.activeConversationIndex);
        }
        const sortedTime = listConversations3[newActiveIndex].lastMessage
          ? listConversations3[newActiveIndex].lastMessage.created_at
          : listConversations3[newActiveIndex].created_at;
        listConversations3[newActiveIndex].sortedTime = sortedTime
          ? sortedTime
          : listConversations3[newActiveIndex]?.sortedTime;
        listConversations3 = sortConversations(listConversations3);
      }
      return {
        ...state,
        conversations: [...listConversations3],
        isConversationActiveAdd: isAddMessageActive,
        activeConversationIndex: newActiveIndex,
        unReadMessages: newUnReadMessages,
      };
    }
    case FETCHING_MESSAGE_REQUEST:
      const newIsFetchingMessages = [...state.isFetchingMessages];
      newIsFetchingMessages.splice(state.activeConversationIndex, 1);
      newIsFetchingMessages.unshift(true);
      return {
        ...state,
        isFetchingMessages: newIsFetchingMessages,
      };
    case TOGGLE_ENGLISHCLUB: {
      state.englishClub.toggleModal = action.payload;
      return {
        ...state,
      };
    }

    case FETCHING_MESSAGE_SUCCESS:
      const findIndex = state.conversations.findIndex(
        (con) => con.id === action.payload
      );
      let newIsFetchingMessages2 = [...state.isFetchingMessages];
      if (findIndex !== -1) {
        newIsFetchingMessages2[findIndex] = false;
      } else {
        // newIsFetchingMessages2 = state.isFetchingMessages.map(() => false);
      }
      return {
        ...state,
        isFetchingMessages: newIsFetchingMessages2,
      };

    case FETCHING_MESSAGE_FAILED:
      const findIndex3 = state.conversations.findIndex(
        (con) => con.id === action.payload
      );
      let newIsFetchingMessages3 = [...state.isFetchingMessages];
      if (findIndex3 !== -1) {
        newIsFetchingMessages3[findIndex3] = false;
      } else {
        newIsFetchingMessages3 = state.isFetchingMessages.map(() => false);
      }
      return {
        ...state,
        isFetchingMessages: newIsFetchingMessages3,
      };

    case GET_HELP_CENTER_SUCCESS:
      return {
        ...state,
        helpCenter: action.payload,
      };
    case DELETE_CONVERSATION_SUCCESS: {
      const deleteIndex = parseInt(action.payload);
      let currentIndex = state.activeConversationIndex;
      if (deleteIndex === currentIndex) {
        if (state.conversations.length > 1) {
          //there are at least two conversations
          if (deleteIndex !== 0) {
            currentIndex--;
          }
        } else {
          currentIndex = 0;
        }
      } else if (deleteIndex < currentIndex) {
        currentIndex--;
      }
      state.conversations.splice(deleteIndex, 1);
      listChat = sortConversations(state.conversations);
      return {
        ...state,
        activeConversationIndex: currentIndex,
        conversations: [...state.conversations],
      };
    }
    case RENAME_CONVERSATION_SUCCESS: {
      const renameIndex = parseInt(action.payload.index);
      const newConversations = state.conversations.map(
        (conversation, index) => {
          if (index === renameIndex) {
            conversation.name = action.payload.name;
          }
          return conversation;
        }
      );
      return {
        ...state,
        conversations: newConversations,
      };
    }

    case ADD_CONVERSATION_REALTIME: {
      const newConversation = action.payload;
      const existingConversation = state.conversations.some(
        (conversationItem) => conversationItem.id === newConversation.id
      );
      let activeIndex = state.activeConversationIndex;
      let updatedConversations;
      let newMembers = [...state.members];
      if (!existingConversation) {
        updatedConversations = sortConversations([
          newConversation,
          ...state.conversations,
        ]);
        activeIndex += 1;
      } else {
        updatedConversations = [...state.conversations];
        const conIndex = updatedConversations.findIndex(
          (item) => item.id === newConversation.id
        );
        if (
          conIndex !== -1 &&
          newConversation.type === CONVERSATION_TYPE.GROUP
        ) {
          let newMembers2 = [];
          newConversation.members.forEach((item) => {
            newMembers2.push(memberParser(item));
          });
          updatedConversations[conIndex].members = newMembers2;
          updatedConversations[conIndex].me = newConversation.me;
          updatedConversations[conIndex].isActivate =
            newConversation.isActivate;
          updatedConversations[conIndex].statusMe = newConversation.statusMe;
          if (state.conversations[activeIndex].id === newConversation.id) {
            newMembers = newMembers2;
          }
        }
      }
      return {
        ...state,
        activeConversationIndex: activeIndex,
        conversations: updatedConversations,
        members: newMembers,
      };
    }

    case UPDATE_CONVERSATION_REALTIME: {
      const newConversation = action.payload;
      const conversationIndex = state.conversations?.findIndex(
        (conversationItem) => conversationItem.id === newConversation.id
      );
      let updateConversations;
      if (conversationIndex !== -1) {
        updateConversations = state.conversations.map((conversation, index) => {
          if (index === conversationIndex) {
            conversation.name = newConversation.name;
            conversation.profilePicture = newConversation.profilePicture;
            conversation.statusMe = newConversation.statusMe;
            conversation.isActivate = newConversation.isActivate;
          }
          return conversation;
        });
      } else {
        updateConversations = state.conversations;
      }
      return {
        ...state,
        conversations: updateConversations,
      };
    }
    case DELETE_CONVERSATION_REALTIME: {
      const conversationId = action.payload;
      let currentIndex = state.activeConversationIndex;
      const deleteIndex = state.conversations.findIndex(
        (conversationItem) => conversationItem.id === conversationId
      );
      let updateConversations;
      if (deleteIndex !== -1) {
        if (deleteIndex === currentIndex) {
          if (state.conversations.length > 1) {
            //there are at least two conversations
            if (deleteIndex !== 0) {
              currentIndex--;
            }
          } else {
            currentIndex = 0;
          }
        } else if (deleteIndex < currentIndex) {
          currentIndex--;
        }
        state.conversations.splice(deleteIndex, 1);
        updateConversations = sortConversations(state.conversations);
      } else {
        updateConversations = state.conversations;
      }
      return {
        ...state,
        activeConversationIndex: currentIndex,
        conversations: updateConversations,
      };
    }

    case FETCH_GROUP_MEMBERS_SUCCESS: {
      let updateMember4 = [];
      if (action.payload?.isReload) {
        if (state.conversations[state.activeConversationIndex].members) {
          state.conversations[state.activeConversationIndex].members.forEach(
            (mem) => {
              updateMember4.push(memberParser(mem));
            }
          );
        }
      } else {
        const listMembers = action.payload?.data;
        const listMembersCurrent = [...state.conversations][
          state.activeConversationIndex
        ].members;
        if (listMembers.length > 0) {
          for (let member of listMembers) {
            const userExist = listMembersCurrent.find(
              (item) => member.user_id === item.user.id
            );
            if (userExist) {
              updateMember4.push(memberParser(userExist));
            }
          }
        }
      }
      return {
        ...state,
        members: updateMember4,
      };
    }
    case FETCH_USER: {
      return {
        ...state,
        isUserFetching: true,
        isUserFetched: false,
      };
    }
    case FETCH_USER_SUCCESS: {
      return {
        ...state,
        listUsers: action.payload,
        isUserFetching: false,
        isUserFetched: true,
      };
    }
    case FETCH_USER_FALSE: {
      return {
        ...state,
        listUsers: [],
        isUserFetching: false,
        isUserFetched: false,
      };
    }
    case ADD_MEMBER_SUCCESS: {
      const newUser = memberParser(action.payload);
      newUser.user_id = action.payload?.id;
      return {
        ...state,
        members: [newUser, ...state.members],
      };
    }
    case REMOVE_MEMBER_SUCCESS: {
      const newUpdateMembers = [...state.members];
      const newUpdateMembers2 = newUpdateMembers.filter(
        (item) => item.id !== action.payload.id
      );
      const updatedConversations = [...state.conversations];
      updatedConversations[state.activeConversationIndex].members =
        newUpdateMembers2;
      return {
        ...state,
        members: newUpdateMembers2,
        conversations: updatedConversations,
      };
    }
    case LEAVE_GROUP_SUCCESS: {
      const convers_new = [...state.conversations];
      const conIndex = convers_new.findIndex(
        (item) => item.id === action.payload.conversation_id
      );
      if (conIndex !== -1) {
        convers_new[conIndex].statusMe = CONVERSATION_STATUS.OUT_GROUP;
        convers_new[conIndex].isActivate = false;
      }
      return {
        ...state,
        conversations: convers_new,
      };
    }
    case TOGGLE_ACTIVATE_GROUP_SUCCESS: {
      const convers_new = [...state.conversations];
      const findConvindex = convers_new.findIndex(
        (item) => item.id === action.payload.id
      );
      if (findConvindex !== -1) {
        convers_new[findConvindex].isActivate =
          action.payload.status === CONVERSATION_STATUS.ACTIVE;
        convers_new[findConvindex].statusMe = action.payload.status;
      }
      return {
        ...state,
        conversations: convers_new,
      };
    }
    case TOGGLE_ACTIVATE_GROUP_FAIL: {
      return {
        ...state,
      };
    }
    case ADD_MEMBER_REALTIME: {
      const data = action.payload;
      let currentConversations = [...state.conversations];
      const currentConve = currentConversations[state.activeConversationIndex];
      const converIndex = currentConversations.findIndex(
        (con) => con.id === data.conversation_id
      );
      const newUser = memberParser(data);
      const existingMember = state.members.some((mem) => mem.id === newUser.id);
      let updatedMembers;
      if (!existingMember) {
        updatedMembers = [newUser, ...state.members];
      } else {
        updatedMembers = state.members;
      }
      if (converIndex !== -1) {
        currentConversations[converIndex].members = updatedMembers;
      }
      let newMembers = [...state.members];
      if (currentConve.id === data.conversation_id) {
        newMembers = [...updatedMembers];
      }
      if (action.payload.user_id === state.user.id) {
        newMembers = [...state.members];
        currentConversations = [...state.conversations];
      }
      return {
        ...state,
        members: newMembers,
        conversations: currentConversations,
      };
    }
    case REMOVE_MEMBER_REALTIME: {
      let currentConversations = [...state.conversations];
      const currentConve = currentConversations[state.activeConversationIndex];
      const converIndex = currentConversations.findIndex(
        (con) => con.id === action.payload.conversation_id
      );
      const newUser = memberParser(action.payload);
      const newUpdatedMembers = [...state.members].filter(
        (item) => item.id !== newUser.id
      );
      let newMembers = [...state.members];

      if (currentConve.id === action.payload.conversation_id) {
        newMembers = [...newUpdatedMembers];
      }
      if (converIndex !== -1) {
        currentConversations[converIndex].members = newUpdatedMembers;
        if (action.payload.user_id === state.user.id) {
          currentConversations[converIndex].isActivate = false;
          currentConversations[converIndex].statusMe =
            CONVERSATION_STATUS.OUT_GROUP;
        }
      }
      return {
        ...state,
        members: newMembers,
        conversations: currentConversations,
      };
    }
    case LEAVE_MEMBER_REALTIME: {
      let currentConversations = [...state.conversations];
      const currentConve = currentConversations[state.activeConversationIndex];
      const converIndex = currentConversations.findIndex(
        (con) => con.id === action.payload.conversation_id
      );
      const newUser = memberParser(action.payload);
      const newUpdatedMembers = [...state.members].filter(
        (item) => item.id !== newUser.id
      );
      let newMembers = [...state.members];
      if (currentConve.id === action.payload.conversation_id) {
        newMembers = [...newUpdatedMembers];
      }
      if (converIndex !== -1) {
        currentConversations[converIndex].members = newUpdatedMembers;
        if (action.payload.user_id === state.user.id) {
          currentConversations[converIndex].isActivate = false;
          currentConversations[converIndex].statusMe =
            CONVERSATION_STATUS.OUT_GROUP;
        }
      }
      return {
        ...state,
        members: newMembers,
        conversations: currentConversations,
      };
    }
    case EDIT_MESSAGE_REQUEST: {
      return {
        ...state,
      };
    }
    case EDIT_MESSAGE_SUCCESS: {
      const newConversations = [...state.conversations];
      const findIndexConversation = newConversations.findIndex(
        (item) => item.id === action.payload.conversation_id
      );
      if (findIndexConversation !== -1) {
        const findMessage = newConversations[
          findIndexConversation
        ].messages.findIndex((mess) => mess.id === action.payload.id);
        if (findMessage !== -1) {
          newConversations[findIndexConversation].messages[
            findMessage
          ].message = action.payload.message;
        }
        if (
          newConversations[findIndexConversation].lastMessage?.id ===
          action.payload.id
        ) {
          newConversations[findIndexConversation].lastMessage.message =
            action.payload.message;
        }
      }
      return {
        ...state,
        conversations: newConversations,
      };
    }
    case EDIT_MESSAGE_FAIL: {
      return {
        ...state,
      };
    }
    case DELETE_MESSAGE_SUCCESS: {
      const newConversations = [...state.conversations];
      const findIndexConversation = newConversations.findIndex(
        (item) => item.id === action.payload.conversation_id
      );
      let newMessages;
      if (findIndexConversation !== -1) {
        newMessages = newConversations[findIndexConversation].messages.filter(
          (mess) => mess.id !== action.payload.id
        );
        newConversations[findIndexConversation].messages = newMessages;
        if (
          newConversations[findIndexConversation].lastMessage?.id ===
          action.payload.id
        ) {
          const lenConvers =
            newConversations[findIndexConversation].messages.length;
          if (lenConvers > 0) {
            newConversations[findIndexConversation].lastMessage =
              newConversations[findIndexConversation].messages[lenConvers - 1];
          }
        }
      }
      return {
        ...state,
        conversations: newConversations,
      };
    }
    case DELETE_MESSAGE_FAIL: {
      return {
        ...state,
      };
    }
    case SET_LOADING_MESSAGE: {
      return {
        ...state,
        isLoadingMessage: action.payload,
      };
    }
    case TOGGLE_NOTIFICATION_SUCCESS: {
      const newConversations = [...state.conversations];
      const findConverIndex = newConversations.findIndex(
        (item) => item.id === action.payload.conversation_id
      );
      if (findConverIndex !== -1) {
        newConversations[findConverIndex].me.has_notification =
          action.payload.has_notification;
      }
      return {
        ...state,
        conversations: newConversations,
      };
    }
    default:
      return { ...state };
  }
};

export default Chat;
