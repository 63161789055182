import {
  ACTIVE_CONVERSATION,
  FULL_CONVERSATION,
  FULL_CONVERSATION_SUCCESS,
  GET_HELP_CENTER,
  GET_HELP_CENTER_SUCCESS,
  GET_ENGLISH_CLUB,
  GET_ENGLISH_CLUB_SUCCESS,
  SEND_MESSAGE,
  MAKE_AS_READ,
  READ_MESSAGE,
  GET_NEW_CONVERSATION,
  GET_NEW_CONVERSATION_SUCCESS,
  CREATE_CHAT,
  CREATE_CHAT_SUCCESS,
  SEEN_MESSAGE,
  LOAD_MORE_CONVERSATION,
  LOAD_MORE_CONVERSATION_SUCCESS,
  FETCH_MESSAGE,
  FETCH_MESSAGE_SUCCESS,
  ADD_MESSAGE,
  FETCHING_MESSAGE_REQUEST,
  FETCHING_MESSAGE_SUCCESS,
  FETCHING_MESSAGE_FAILED,
  TOGGLE_HELPCENTER,
  DELETE_CONVERSATION_SUCCESS,
  RENAME_CONVERSATION_SUCCESS,
  GET_CHAT_BOT_TYPE,
  GET_CHAT_BOT_TYPE_SUCCESS,
  ADD_CONVERSATION_REALTIME,
  UPDATE_CONVERSATION_REALTIME,
  DELETE_CONVERSATION_REALTIME,
  FETCH_GROUP_MEMBERS,
  FETCH_GROUP_MEMBERS_SUCCESS,
  FETCH_USER_FALSE,
  FETCH_USER,
  FETCH_USER_SUCCESS,
  ADD_MEMBER_REQUEST,
  ADD_MEMBER_FALSE,
  ADD_MEMBER_SUCCESS,
  REMOVE_MEMBER_REQUEST,
  REMOVE_MEMBER_SUCCESS,
  REMOVE_MEMBER_FALSE,
  LEAVE_GROUP_REQUEST,
  LEAVE_GROUP_FALSE,
  LEAVE_GROUP_SUCCESS,
  EDIT_GROUP_AVATAR_REQUEST,
  EDIT_GROUP_AVATAR_FAIL,
  EDIT_GROUP_AVATAR_SUCCESS,
  TOGGLE_ACTIVATE_GROUP,
  TOGGLE_ACTIVATE_GROUP_SUCCESS,
  TOGGLE_ACTIVATE_GROUP_FAIL,
  ADD_MEMBER_REALTIME,
  REMOVE_MEMBER_REALTIME,
  LEAVE_MEMBER_REALTIME,
  EDIT_MESSAGE_REQUEST,
  EDIT_MESSAGE_SUCCESS,
  DELETE_MESSAGE_REQUEST,
  DELETE_MESSAGE_SUCCESS,
  DELETE_MESSAGE_FAIL,
  GET_UNREAD_MESSAGES_SUCCESS,
  TOGGLE_NOTIFICATION_SUCCESS,
  TOGGLE_NOTIFICATION,
  TOGGLE_ENGLISHCLUB,
} from './constants';

export const activateConversation = (conversation_id) => ({
  type: ACTIVE_CONVERSATION,
  payload: conversation_id,
});

export const fetchAllConversations = (payload) => {
  return {
    type: FULL_CONVERSATION,
    payload,
  };
};

export const fetchAllConversationsSuccess = (fullConversation) => ({
  type: FULL_CONVERSATION_SUCCESS,
  payload: fullConversation,
});

export const sendMessage = (message) => ({
  type: SEND_MESSAGE,
  payload: message,
});

export const readMessage = (conversation_id) => ({
  type: READ_MESSAGE,
  payload: conversation_id,
});

export const seenMessage = (conversation_id) => ({
  type: SEEN_MESSAGE,
  payload: conversation_id,
});

export const markAsRead = (conversation_id) => ({
  type: MAKE_AS_READ,
  payload: conversation_id,
});

export const getNewUser = (conversation_id) => ({
  type: GET_NEW_CONVERSATION,
  payload: conversation_id,
});

export const getNewUserSuccess = (userData) => ({
  type: GET_NEW_CONVERSATION_SUCCESS,
  payload: userData,
});

export const createChat = (object) => ({
  type: CREATE_CHAT,
  payload: object,
});

export const creatChatSuccess = (conversation) => ({
  type: CREATE_CHAT_SUCCESS,
  payload: conversation,
});

export const loadMoreUser = (payload) => ({
  type: LOAD_MORE_CONVERSATION,
  payload,
});

export const loadMoreUserSuccess = (conversation) => ({
  type: LOAD_MORE_CONVERSATION_SUCCESS,
  payload: conversation,
});

export const fetchMessages = (params) => ({
  type: FETCH_MESSAGE,
  payload: params,
});

export const fetchMessagesSuccess = (
  conversation_id,
  messages,
  lastPage,
  currentPage
) => ({
  type: FETCH_MESSAGE_SUCCESS,
  payload: { conversation_id, messages, lastPage, currentPage },
});

export const addMessage = (newMessage) => ({
  type: ADD_MESSAGE,
  payload: newMessage,
});

export const fetchingMessageRequest = () => ({
  type: FETCHING_MESSAGE_REQUEST,
});

export const fetchingMessageSuccess = (payload) => ({
  type: FETCHING_MESSAGE_SUCCESS,
  payload,
});

export const fetchingMessageFailed = (payload) => ({
  type: FETCHING_MESSAGE_FAILED,
  payload,
});

export const getHelpCenter = () => ({
  type: GET_HELP_CENTER,
});

export const getHelpCenterSuccess = (conversation) => ({
  type: GET_HELP_CENTER_SUCCESS,
  payload: conversation,
});
export const getEnglishClub = () => ({
  type: GET_ENGLISH_CLUB,
});

export const getEnglishClubSuccess = (conversation) => ({
  type: GET_ENGLISH_CLUB_SUCCESS,
  payload: conversation,
});

export const toggleHelpcenter = (input) => ({
  type: TOGGLE_HELPCENTER,
  payload: input,
});
export const toggleEnglishclub = (input) => ({
  type: TOGGLE_ENGLISHCLUB,
  payload: input,
});

export const deleteConversationSuccess = (input) => ({
  type: DELETE_CONVERSATION_SUCCESS,
  payload: input,
});

export const renameConversationSuccess = (input) => ({
  type: RENAME_CONVERSATION_SUCCESS,
  payload: input,
});

export const getChatBotType = () => ({
  type: GET_CHAT_BOT_TYPE,
});

export const getChatBotTypeSuccess = (input) => ({
  type: GET_CHAT_BOT_TYPE_SUCCESS,
  payload: input,
});

export const addConversationRealtime = (input) => ({
  type: ADD_CONVERSATION_REALTIME,
  payload: input,
});

export const updateConversationRealtime = (input) => ({
  type: UPDATE_CONVERSATION_REALTIME,
  payload: input,
});

export const deleteConversationRealtime = (input) => ({
  type: DELETE_CONVERSATION_REALTIME,
  payload: input,
});

export const fetchGroupMembers = (payload) => {
  return {
    type: FETCH_GROUP_MEMBERS,
    payload,
  };
};

export const fetchGroupMembersSuccess = (payload) => {
  return {
    type: FETCH_GROUP_MEMBERS_SUCCESS,
    payload,
  };
};

export const fetchUserRequest = (payload) => {
  return {
    type: FETCH_USER,
    payload,
  };
};
export const fetchUserSuccess = (payload) => {
  return {
    type: FETCH_USER_SUCCESS,
    payload,
  };
};
export const fetchUserFalse = (payload) => {
  return {
    type: FETCH_USER_FALSE,
    payload,
  };
};
export const addMemberRequest = (payload) => {
  return {
    type: ADD_MEMBER_REQUEST,
    payload,
  };
};
export const addMemberSuccess = (payload) => {
  return {
    type: ADD_MEMBER_SUCCESS,
    payload,
  };
};
export const addMemberFalse = (payload) => {
  return {
    type: ADD_MEMBER_FALSE,
    payload,
  };
};
export const removeMemberRequest = (payload) => {
  return {
    type: REMOVE_MEMBER_REQUEST,
    payload,
  };
};
export const removeMemberSuccess = (payload) => {
  return {
    type: REMOVE_MEMBER_SUCCESS,
    payload,
  };
};
export const removeMemberFalse = (payload) => {
  return {
    type: REMOVE_MEMBER_FALSE,
    payload,
  };
};
export const leaveGroupRequest = (payload) => {
  return {
    type: LEAVE_GROUP_REQUEST,
    payload,
  };
};
export const leaveGroupSuccess = (payload) => {
  return {
    type: LEAVE_GROUP_SUCCESS,
    payload,
  };
};
export const leaveGroupFalse = (payload) => {
  return {
    type: LEAVE_GROUP_FALSE,
    payload,
  };
};

export const editAvatarGroup = (payload) => {
  return {
    type: EDIT_GROUP_AVATAR_REQUEST,
    payload,
  };
};
export const editAvatarGroupSuccess = (payload) => {
  return {
    type: EDIT_GROUP_AVATAR_SUCCESS,
    payload,
  };
};
export const editAvatarGroupFail = (payload) => {
  return {
    type: EDIT_GROUP_AVATAR_FAIL,
    payload,
  };
};
export const toggleActivateGroup = (payload) => {
  return {
    type: TOGGLE_ACTIVATE_GROUP,
    payload,
  };
};
export const toggleActivateGroupSuccess = (payload) => {
  return {
    type: TOGGLE_ACTIVATE_GROUP_SUCCESS,
    payload,
  };
};
export const toggleActivateGroupFail = (payload) => {
  return {
    type: TOGGLE_ACTIVATE_GROUP_FAIL,
    payload,
  };
};
export const addMemberRealtime = (payload) => {
  return {
    type: ADD_MEMBER_REALTIME,
    payload,
  };
};
export const removeMemberRealtime = (payload) => {
  return {
    type: REMOVE_MEMBER_REALTIME,
    payload,
  };
};
export const leaveMemberRealtime = (payload) => {
  return {
    type: LEAVE_MEMBER_REALTIME,
    payload,
  };
};
export const editMessageRequest = (payload) => {
  return {
    type: EDIT_MESSAGE_REQUEST,
    payload,
  };
};
export const editMessageSuccess = (payload) => {
  return {
    type: EDIT_MESSAGE_SUCCESS,
    payload,
  };
};
export const editMessageFail = (payload) => {
  return {
    type: EDIT_GROUP_AVATAR_FAIL,
    payload,
  };
};
export const deleteMessageRequest = (payload) => {
  return {
    type: DELETE_MESSAGE_REQUEST,
    payload,
  };
};
export const deleteMessageSuccess = (payload) => {
  return {
    type: DELETE_MESSAGE_SUCCESS,
    payload,
  };
};
export const deleteMessageFail = (payload) => {
  return {
    type: DELETE_MESSAGE_FAIL,
    payload,
  };
};
export const getUnreadMessagesSuccess = (payload) => {
  return {
    type: GET_UNREAD_MESSAGES_SUCCESS,
    payload,
  };
};
export const toggleNotification = (payload) => {
  return {
    type: TOGGLE_NOTIFICATION,
    payload,
  };
};
export const toggleNotificationSuccess = (payload) => {
  return {
    type: TOGGLE_NOTIFICATION_SUCCESS,
    payload,
  };
};
