import React, { useState, useEffect, useCallback } from 'react';
import { Button, Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import i18next from 'i18next';
import { logoutUser } from 'src/redux/actions';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function PopupKickout({ isOpen }) {
  const [countdown, setCountdown] = useState(5);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (isOpen) {
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown === 1) {
            clearInterval(timer);
          }
          return prevCountdown - 1;
        });
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [isOpen]);

  const logout = useCallback(() => {
    dispatch(logoutUser(history));
  }, [dispatch, history]);

  useEffect(() => {
    if (countdown === 1) {
      logout();
    }
  }, [countdown, logout]);

  return (
    <Modal isOpen={isOpen} centered={true}>
      <ModalHeader>{i18next.t('Warning')}</ModalHeader>
      <ModalBody>
        <p>{i18next.t('kickout_message')}</p>
        <div
          style={{
            fontSize: '64px',
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          {countdown}s
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={logout}>
          {i18next.t('Logout')}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default PopupKickout;
